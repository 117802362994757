body {
  font-family:"Century Gothic",Verdana,sans-serif; 
}
p {
  font-family:"Century Gothic",Verdana,sans-serif; 
}
span {
  font-family:"Century Gothic",Verdana,sans-serif; 
}
.jm-logo{
    max-width:300;
}
.App {
  position:absolute;
  display:flex;
  width:100%;
  height:100%;
  background:#1e1e1e;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 120px;
  -webkit-overflow-scrolling: touch
}
.creditCardContainer{
  width:300px;
  height:180px;
  background-color: transparent;
  border-radius: 20px;
}
.alineado{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}
.containerFunction:hover{
  background-color: darkgrey;
  border-radius: 10px;
}
.containerDate:hover{
  background-color: darkgrey;
}
.minisizeable{
  -webkit-transition:all .5s ease; /* Safari y Chrome */
  -moz-transition:all .5s ease; /* Firefox */
  -o-transition:all .5s ease; /* IE 9 */
  -ms-transition:all .5s ease; /* Opera */
}
.input{
  padding: 5px 10px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  box-shadow: .5px 1px 1px 1px #c1c1c1;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
  outline: none;
  background-color: transparent;
}
.input::placeholder {
  color:#fff;
  font-weight: bold;
}
.hipnoBackground{
  background-color: #2b2b35;
  background-repeat: no-repeat;
  background-size: cover;
}
.modal-body{
  background-color: #1e1e1e;
}
.minisizeable:hover {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
  transform: scale(1.02);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
