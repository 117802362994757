.jm-footbar{
    width:100%;
    height:50px;
    position:fixed;
    bottom:0px;
    background-color: #32323b;
}
.jm-footbar-label-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.jm-footbar-label-element{
    display:flex;
    flex-direction: row;
    height: 100%;
    padding:10px
}
.jm-footbar-label{
    margin-left: 5px;
    font-weight: bold;
}